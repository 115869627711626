<template>
    
    <div class="projekte-wrapper">  
        <b-container>
        <div class="projekte content">
            
                <!--Platzhalter während Datenbankj lädt-->
                <div class="projekt-wrapper" v-if="!loaded">
                    <div class="projekt">
                        <b-row>
                            <b-col lg="10" md="9" order-md="2">
                                <div class="projekt-slider">
                                    <img src="../assets/wohnprojekt-ruhr.jpg" class="slider-image">
                                </div> <!--projekt-slider-->   
                            </b-col>
                            <b-col lg="2" md="3" order-md="1">
                                <div class="projekt-text">
                                    <h2>Loading...</h2>
                                    <div></div>
                                </div>
                            </b-col>
                            
                        </b-row>     
                    </div><!--projekt-->
                </div>
                <!--Ende Platzhalter-->
                <div class="projekt-wrapper" v-for="(projekt, index) in  projekte" :key="projekt.title"> 
                    <transition name="fade">
                        <div class="projekt" v-if="index == counter || mobile">
                            <b-row>
                                  <b-col lg="10" md="9" order-md="2">
                                    <div class="projekt-slider">

                                            <div v-for="(bild, xdex) in projekte[index].bilder" :key="bild.index">
                                                <transition name="fade">
                                                    
                                                    <img v-if="xdex == projekte[index].bildCount" :src="bild" class="slider-image">
                                                  
                                                </transition>
                                            </div>
                                                                                    
                                        <div class="next" v-on:click="bildPlus(index)">
                                            <img v-if="!mobile" src="../assets/next-arrow.svg">
                                            <img v-else src="../assets/next-arrow-sm.svg">
                                        </div>
                                        <div class="prev" v-on:click="bildMinus(index)">
                                            <img v-if="!mobile" src="../assets/pre-arrow.svg">
                                            <img v-else src="../assets/pre-arrow-sm.svg">
                                        </div>
                                    </div> <!--projekt-slider-->
                                        
                                </b-col>
                                <b-col lg="2" md="3" order-md="1">
                                    <div class="projekt-text">
                                        <h2>{{ projekt.title }}</h2>
                                        <div v-html='projekt.content' class="text"></div>
                                        <div class="button-wrapper">          
                                            <div class="link-button text-rot" v-on:click="sliderMinus">Prev Project</div>
                                            <div class="link-button text-rot" v-on:click="sliderPlus">Next Project</div>
                                        </div><!--button-wrapper-->
                                    </div>
                                </b-col>
                              
                            </b-row>      

                        </div><!--projekt-->
                    </transition>
                    
                </div><!--.projekt-wrapper-->
                        
            
        </div>   
        </b-container>
    </div>

</template>

<script>
import axios from 'axios'

export default {
    props:["mobile"],
    name: 'Projekte',
    components: {

    },
    data () {
        return {
            slug: this.$route.params.slug,
            projekte: [],
            counter: 0
        }
    },
    computed: {
        loaded: function () {
            if (this.projekte.length > 0) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        bildPlus: function (index) {
            let lastBild = this.projekte[index].bilder.length -1
            this.projekte[index].bildCount += 1
            if(this.projekte[index].bildCount > lastBild) {
                this.projekte[index].bildCount = 0
            }
        },
        bildMinus: function (index) {
            let lastBild = this.projekte[index].bilder.length -1
            this.projekte[index].bildCount -= 1
            if(this.projekte[index].bildCount < 0) {
                this.projekte[index].bildCount = lastBild
            }
        },
        sliderPlus: function () {
            let lastSlide = this.projekte.length - 1
            this.counter += 1
            if (this.counter > lastSlide) {
                this.counter = 0
            }
        },
        sliderMinus: function () {
            let lastSlide = this.projekte.length - 1
            this.counter -=1
            if (this.counter < 0) {
                this.counter = lastSlide
            }
        }
    },
    created () {
        
        axios.get('https://wordpress.nikolaigust.de/wp-json/wp/v2/projects?' )
        .then(res => {     
   
            for(let projekt in res.data) {
                let customProject = {}
                customProject.title = res.data[projekt].title.rendered
                customProject.content = res.data[projekt].content.rendered
                customProject.bildCount = 0
                customProject.bilder = []
                Object.keys(res.data[projekt].acf).forEach( function checkKeys(key) {
                    if (res.data[projekt].acf[key]) {
                        if(key.includes('bild')) {
                            const bild = res.data[projekt].acf[key].sizes.projekte
                            if (bild.length > 0 && bild.includes('wp-content')) {
                                customProject.bilder.push(bild)
                            } 
                        }
                    }
                    
                })
                this.projekte.push(customProject)
            }
        })
    }
}

</script>

<style lang="scss">

.projekte-wrapper {
    //position: relative;
    color: rgba(255, 70, 108, 1);
    width: 100vw;
    overflow: visible;
    
}

 .projekt-wrapper {
        z-index: 1011;
        max-height: 80vh;
        
        @media screen and (max-width: 767px) {
            max-height: 100%;
        }
    }

.projekte {
    z-index: 1001; 
     @media screen and (max-width: 767px) {
        padding-bottom: 80px;
      }

    .projekt {
        position: static;
        overflow: visible;
       
        @media screen and (min-width: 768px) {
            max-height: 80vh;
            padding-bottom: 0;
        }       
    }
   

    .projekt-text {
        position: relative;
        height: 100%;
        max-height: 70vh;    
        z-index: 100;
        
        @media screen and (max-width: 767px) {
                max-height: auto;
                height: auto;
                margin-bottom: 65px;

                h2 {
                    max-width: calc( 100% - 130px );
                    margin-top: 13px;
                    font-size: 26px;
                }

                .text {
                    position: relative;
                    padding-top: 13px;
                   
                }
            }

        h2 {
            font-size: 26px;
            margin-bottom: 25px;
            font-family: 'LeagueGothic';
            font-style: italic;
        }

        p {
            font-size: 14px;
            line-height: 21px;
            font-style: 'M+ 1m';
            font-style: light;
        }

        a, a:visited, a:hover {
            color: rgba(255, 70, 108, 1);
            text-decoration: underline;
        }

        .button-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            @media screen and (max-width: 767px) {
                display: none;
            }

            .link-button { //Übergeordnetes Button Desin in custom.scss
                background-color: #fff;
                margin-top: 1.5em;
                border: 2px solid rgba(255, 70, 108, 1);
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                z-index: -1;
                padding: 18px;
                border-radius: 27px;
                width: 100%;
                transition: box-shadow 0.3s ease;

                &:hover {
                    text-decoration: none;
                    box-shadow: 0 0 7em 0 rgba(255, 70, 108, 0.6);;
                }
            }
        }

    }

    .projekt-slider {
        display: inline-block;
        position: relative;
        max-height: 70vh;
        z-index: 101;
        border: 2px solid  rgba(255, 70, 108, 1);   
        box-sizing: content-box;

        img.slider-image {
            
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 70vh;
        }

        .next, .prev{
            position: absolute;
            bottom: 30px;
            max-height: 100px;
            max-width: 100px;
            height: 100px;
            width: 100px;
            border: 2px solid  rgba(255, 70, 108, 1); 
            background-color: #fff;
            border-radius: 50px;
            transition: box-shadow 0.3s ease;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                width: 50px;
                height: 50px;
                border-radius: 25px;
            }

            img {
                margin: 18%;
                width: 64%;
            }

            &:hover {
                text-decoration: none;
                box-shadow: 0 0 7em 0 rgba(255, 70, 108, 0.6);
            }
        }

        .prev {
            left: 30px;
        }

        .next {
            right: 30px;
        }

        @media screen and (max-width: 767px) {
                max-height: 100%;
                display: block;

                img.slider-image {
                    max-height: 100%;
                }

                .next, .prev {
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                    bottom: -61px;
                }

                .prev {
                    left: auto;
                    right: 68px;
                }

                .next {
                    right: 0;
                }
            }
    }

    #kreis {
    left: 110%;
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-active {
   // transition-delay: 1s;
    display: none;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
   
}


</style>
